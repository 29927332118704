import { type ClientLoaderFunctionArgs, useLoaderData } from '@remix-run/react';
import { useTitle } from 'react-use';

import { CheckoutCompleted, OTPUtils } from '../components/OneTimePurchase';
import { makeTitle } from '../utils/common';
import { useCheckoutLoaderData } from './checkout.client';

export const clientLoader = async (action: ClientLoaderFunctionArgs) => {
  const url = new URL(action.request.url);

  return {
    headcount: OTPUtils.ParseHeadcount(url.searchParams.get('headcount')),
    priceId: url.searchParams.get('price-id'),
    sessionId: url.searchParams.get('session-id'),
    clientSecret: url.searchParams.get('client-secret'),
    invoiceId: url.searchParams.get('invoice-id'),
  };
};

export function Component() {
  const { pack, product } = useCheckoutLoaderData();
  const props = useLoaderData<typeof clientLoader>();
  useTitle(makeTitle(`Checkout Completed | Purchase ${pack.name}`));

  return <CheckoutCompleted {...props} pack={pack} product={product} />;
}
