import { getEnv } from '../../config/getEnv';
import { marketing } from '../../config/marketing';

declare global {
  interface Window {
    uetq?: unknown[];
  }
}

export function uetq(command: string, ...args: unknown[]) {
  const config = marketing(getEnv());
  if (!config.registrationMarketingTrackingEnabled) return;
  window.uetq = window.uetq || [];
  window.uetq.push(command, ...args);
}
